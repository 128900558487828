import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCcMastercard,
  faGoogle,
  faInstagram,
  faTiktok,
  faYoutube,
  faFacebook,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

import {
  faEye,
  faEyeSlash,
  faHeart as faRegularHeart,
  faSquareMinus,
  faCreditCard,
} from "@fortawesome/free-regular-svg-icons";

import {
  faTags,
  faList,
  faClockRotateLeft,
  faTrash,
  faPencil,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faArrowLeft,
  faHeart as faSolidHeart,
  faCircleMinus,
  faCirclePlus,
  faShoppingCart,
  faMoneyBills,
  faQrcode,
  faReceipt,
  faBookmark,
  faCircleXmark,
  faMagnifyingGlass,
  faLocationDot,
  faPhone,
  faShareNodes,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faMotorcycle,
  faBagShopping,
  faUtensils,
  faIceCream,
  faCookieBite,
  faPercent,
  faFilter,
  faX,
  faSignOut,
  faUsers,
  faWallet,
  faDoorOpen,
  faUserGear,
  faUserTie,
  faFloppyDisk,
  faDoorClosed,
  faInbox,
  faCalendarCheck,
  faTableCells,
  faClipboard,
  faTicket,
  faShop,
  faCircleInfo,
  faMars,
  faVenus,
  faRightFromBracket,
  faPlus,
  faArrowsRotate,
  faComments,
  faComment,
  faTimes,
  faPaperclip,
  faPaperPlane,
  faBoxOpen,
  faBoxesStacked,
  faBell,
  faCamera,
  faEllipsisVertical,
  faLaptopFile,
  faBriefcase,
  faImages,
  faTableList,
  faBars,
  faHashtag,
  faGlobe,
  faCalendarDay,
  faPhoneSquareAlt,
  faPhoneSquare,
  faArrowRight,
  faMinus,
  faEdit,
  faAngleUp,
  faAngleDown,
  faExclamationCircle,
  faFileInvoiceDollar,
  faExclamationTriangle,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFileInvoiceDollar,
  faSignOut,
  faGoogle,
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faArrowLeft,
  faSolidHeart,
  faRegularHeart,
  faSquareMinus,
  faCircleMinus,
  faDownload,
  faCirclePlus,
  faShoppingCart,
  faMoneyBills,
  faQrcode,
  faReceipt,
  faBookmark,
  faMagnifyingGlass,
  faLocationDot,
  faPhone,
  faShareNodes,
  faCheck,
  faList,
  faChevronLeft,
  faChevronRight,
  faMotorcycle,
  faBagShopping,
  faUtensils,
  faIceCream,
  faCookieBite,
  faPercent,
  faFilter,
  faX,
  faPencil,
  faTrash,
  faClockRotateLeft,
  faTags,
  faUsers,
  faWallet,
  faDoorOpen,
  faUserGear,
  faUserTie,
  faFloppyDisk,
  faDoorClosed,
  faInbox,
  faCalendarCheck,
  faTableCells,
  faClipboard,
  faTicket,
  faShop,
  faCircleInfo,
  faMars,
  faVenus,
  faEye,
  faRightFromBracket,
  faEyeSlash,
  faPlus,
  faArrowsRotate,
  faComments,
  faComment,
  faTimes,
  faPaperclip,
  faPaperPlane,
  faBoxOpen,
  faBoxesStacked,
  faBell,
  faCamera,
  faEllipsisVertical,
  faLaptopFile,
  faBriefcase,
  faCcMastercard,
  faCreditCard,
  faImages,
  faTableList,
  faBars,
  faHashtag,
  faGlobe,
  faInstagram,
  faTiktok,
  faYoutube,
  faFacebook,
  faCircleXmark,
  faCalendarDay,
  faPhoneSquare,
  faArrowRight,
  faMinus,
  faEdit,
  faAngleUp,
  faAngleDown,
  faExclamationCircle,
  faExclamationTriangle,
  faWhatsapp
);

export { FontAwesomeIcon };
