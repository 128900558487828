export default {
  namespaced: true,
  state: {
    itemDataVuex: null,
    cuisineVuex: null,
    facilityVuex: null,
    paymentMethodVuex: null,
    openVuex: null,
    closeVuex: null,
  },
  mutations: {
    RestoCreate(state, { itemDataVuex }) {
      state.itemDataVuex = itemDataVuex;
    },
    RestoCreateCuisine(state, { cuisineVuex }) {
      state.cuisineVuex = cuisineVuex;
    },
    RestoCreateFacility(state, { facilityVuex }) {
      state.facilityVuex = facilityVuex;
    },
    RestoCreatePaymentMethod(state, { paymentMethodVuex }) {
      state.paymentMethodVuex = paymentMethodVuex;
    },
    RestoCreateOpenHours(state, { openVuex }) {
      state.openVuex = openVuex;
    },
    RestoCreateCloseHours(state, { closeVuex }) {
      state.closeVuex = closeVuex;
    },
  },
  actions: {
    setPageRestoCreate: ({ commit }, { itemDataVuex }) => {
      commit("RestoCreate", JSON.stringify({ itemDataVuex }));
      localStorage.setItem("RestoCreate", JSON.stringify(itemDataVuex));
    },
    setPageRestoCreateCuisine: ({ commit }, { cuisineVuex }) => {
      commit("RestoCreateCuisine", { cuisineVuex });
      localStorage.setItem("RestoCreateCuisine", JSON.stringify(cuisineVuex));
    },
    setPageRestoCreateFacility: ({ commit }, { facilityVuex }) => {
      commit("RestoCreateFacility", { facilityVuex });
      localStorage.setItem("RestoCreateFacility", JSON.stringify(facilityVuex));
    },
    setPageRestoCreatePaymentMethod: ({ commit }, { paymentMethodVuex }) => {
      commit("RestoCreatePaymentMethod", { paymentMethodVuex });
      localStorage.setItem(
        "RestoCreatePaymentMethod",
        JSON.stringify(paymentMethodVuex)
      );
    },
    setPageRestoCreateOpenHours: ({ commit }, { openVuex }) => {
      commit("RestoCreateOpenHours", { openVuex });
      localStorage.setItem("RestoCreateOpen", JSON.stringify(openVuex));
    },
    setPageRestoCreateCloseHours: ({ commit }, { closeVuex }) => {
      commit("RestoCreateCloseHours", { closeVuex });
      localStorage.setItem("RestoCreateClose", JSON.stringify(closeVuex));
    },
  },
  getters: {
    getPageRestoCreate: () => {
      if (
        localStorage.getItem("RestoCreate") != undefined &&
        localStorage.getItem("RestoCreate") != "undefined"
      ) {
        return JSON.parse(localStorage.getItem("RestoCreate"));
      } else {
        return null;
      }
    },
    getPageRestoCreateCuisine: () => {
      if (localStorage.getItem("RestoCreateCuisine") != undefined) {
        return JSON.parse(localStorage.getItem("RestoCreateCuisine"));
      } else {
        return null;
      }
    },
    getPageRestoCreateFacility: () => {
      if (localStorage.getItem("RestoCreateFacility") != undefined) {
        return JSON.parse(localStorage.getItem("RestoCreateFacility"));
      } else {
        return null;
      }
    },
    getPageRestoCreatePaymentMethod: () => {
      if (localStorage.getItem("RestoCreatePaymentMethod") != undefined) {
        return JSON.parse(localStorage.getItem("RestoCreatePaymentMethod"));
      } else {
        return null;
      }
    },
    getPageRestoCreateOpenHours: () => {
      if (localStorage.getItem("RestoCreateOpen") != undefined) {
        return JSON.parse(localStorage.getItem("RestoCreateOpen"));
      } else {
        return null;
      }
    },
    getPageRestoCreateCloseHours: () => {
      if (localStorage.getItem("RestoCreateClose") != undefined) {
        return JSON.parse(localStorage.getItem("RestoCreateClose"));
      } else {
        return null;
      }
    },
  },
};
