<template>
  <div class="body">
    <div class="main-wrapper">
      <div class="main-content">
        <div class="container container-welcome text-center">
          <img class="mx-auto mb-5" src="@/assets/moopo.png" alt="" />
          <h1 class="text-moopo font-weight-bold text-150rem">
            Reset Password
          </h1>
          <p class="text-moopo mb-4 mx-3">
            Kami akan mengirimkan petunjuk pengaturan ulang kata sandi melalui email Anda.
          </p>
          <Form class="text-left" @submit="handleResetPassword" :validation-schema="schema" v-slot="{ errors }">
            <!-- <div v-if="!successful"> -->
            <div class="form-group">
              <label for="email">Email</label>
              <Field id="email" name="email" type="email" class="form-control" :class="{ 'is-invalid': errors.email }" />
              <ErrorMessage name="email" class="invalid-feedback" as="div" />
            </div>

            <button :disabled="loading" id="btn-reset-password"
              class="btn btn-lg btn-moopo text-white btn-rounded w-100 mb-2 mt-3">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              Kirim E-mail
            </button>
            <!-- </div> -->
          </Form>
          <!-- <div v-if="message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
                {{ message }}
            </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- modal -->
  <template v-if="successModal">
    <div class="modal fade show d-block" tabindex="-1" role="dialog" id="SuccessModal">
      <div class="vertical-alignment-helper px-3">
        <div class="modal-dialog vertical-align-center" role="document">
          <div class="modal-content">
            <Form class="text-left">
              <div class="modal-header">
                <div class="col-12">
                  <div class="modal-title text-center text-150rem text-moopo">Berhasil Mengirim Email</div>
                </div>
              </div>
              <div class="modal-body scrollable-content">
                <div class="text-dark text-1rem">Silahkan cek Email Anda</div>
              </div>
              <div class="modal-footer center-content">
                <button @click="agree()" class="btn text-white bg-moopo">
                  Oke
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup>
import { Form, Field, ErrorMessage } from "vee-validate";
import { useRouter } from "vue-router";
import { ref, inject } from "vue";
import * as yup from "yup";
import axios from "axios";

const loading = ref(false)
const router = useRouter()
const successModal = ref(false)
const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required!")
    .email("Email is invalid!")
    .max(50, "Must be maximum 50 characters!"),
});
const api_url = inject("mainUrl") + "/api/auth/forgot-password";

function handleResetPassword(user, actions) {
  loading.value = true;

  let form = new FormData();
  form.append("email", user.email);

  axios
    .post(api_url, form)
    .then((res) => {
      if (res.status == 200) {
        loading.value = false;
       successModal.value = true;
      }
    })
    .catch((err) => {
      console.log(err);
      actions.setFieldError("email", err.response.data.message);
      loading.value = false;
    });

}

function agree() {
 successModal.value = false;
  router.push("/login");
}

</script> 
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Nunito", sans-serif;
}

h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
  font-weight: 500;
}

.main-wrapper {
  background-color: #fff;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-kamsia {
  color: #604226;
}

img {
  max-height: 10vh;
}

.main-content {
  margin-top: -30px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
}
</style>
  