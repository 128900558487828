export default {
  namespaced: true,
  state: {
    id: null,
    theme: null,
    name: null,
  },
  mutations: {
    set(state, item) {
      localStorage.setItem("theme", JSON.stringify(item));

      if (item.theme == undefined) {
        item.theme = {
          is_need_request: false,
        };
      } else {
        item.theme.is_need_request = false;
      }

      state.id = item.id;
      state.theme = item.theme;
      state.name = item.name;
    },
  },
  actions: {
    setTheme({ commit }, item) {
      commit("set", {
        id: item.id,
        theme: item.theme[0],
        name: item.name,
      });
    },
  },
  getters: {
    get: (state) => (id) => {
      if (state.id == null || state.name == null || state.theme == null) {
        let localedTheme = JSON.parse(localStorage.getItem("theme"));
        if (localedTheme != undefined && localedTheme.id == id) {
          return {
            id: localedTheme.id,
            name: localedTheme.name,
            theme: localedTheme.theme,
            is_need_request: false,
          };
        } else {
          console.log("tes");
          return {
            id: undefined,
            name: undefined,
            theme: undefined,
          };
        }
      } else {
        return {
          id: state.id,
          name: state.name,
          theme: state.theme,
          is_need_request: false,
        };
      }
    },
  },
};
