<template>
  <div class="body">
    <div class="main-wrapper">
      <div class="main-content">
        <div class="container container-welcome text-center">
          <img class="mx-auto mb-5" src="@/assets/moopo.png" alt="" />
          <p class="text-moopo mb-4 mx-3">
            Selamat datang di Moopo! Temukan barang yang anda inginkan dari berbagai penjuru kota dalam genggaman Anda.
          </p>
          <a href="/home" id="btn-welcome" class="btn btn-welcome btn-lg btn-rounded w-100">
            Mulai Sekarang !
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeComponent",
  props: {
    //
  },
  mounted() {
    if (
      localStorage.getItem("user") !== null &&
      localStorage.getItem("user") !== ""
    ) {
      this.$router.push("/home");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Nunito", sans-serif;
}

h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
  font-weight: 500;
}

.main-wrapper {
  background-color: #fff;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-moopo {
  color: #1B97DF;
}

img {
  max-height: 10vh;
}

.main-content {
  margin-top: -30px;
}
</style>
