<template>
    <div class="body mb-5">
        <div v-if="isLoading" class="loading-container">
            <img src="@/assets/loading.gif" alt="Loading" class="loading-icon" />
        </div>
        <section class="section">
            <div class="container">
                <div class="d-flex w-100 mt-4 mb-3 px-2">
                    <a href="/owner/product" class="font-weight-bold text-dark text-1rem">
                        <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"
                            class="icon-xl mr-3"></font-awesome-icon>
                        Kategori
                    </a>
                </div>
                <div class="row mb-5">
                    <div class="col-12" v-for="(item, itemIndex) in items" :key="itemIndex">
                        <div class="card rounded-10 shadow-primary mb-3">
                            <div class="card-body p-1">
                                <div class="col-12 p-0 py-1 px-2">
                                    <div class="row">
                                        <div class="col-9">
                                            <div class="text-dark text-75rem font-weight-normal mb-0">
                                                {{ item.name }}
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="d-flex" v-if="item.is_editable">
                                                <a @click="prepareForm(itemIndex)" data-toggle="modal"
                                                    data-target="#addSubCategory" class="text-dark mr-3">
                                                    <font-awesome-icon icon="fa-solid fa-pencil"
                                                        size="lg"></font-awesome-icon>
                                                </a>
                                                <a @click="deleteCategory(item.id)" class="text-danger">
                                                    <font-awesome-icon icon="fa-solid fa-trash"
                                                        size="lg"></font-awesome-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="w-100 action-bar">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <button @click="prepareForm(null)" data-toggle="modal" data-target="#addSubCategory"
                        class="btn btn-icon btn-lg text-1rem btn-block bg-moopo text-white rounded-20">
                        <font-awesome-icon icon="fa-solid fa-plus" size="xl" class="mr-2"></font-awesome-icon>
                        Tambah Sub Kategori
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- modal -->
    <div class="modal fade" ref="addSubCategory" tabindex="-1" role="dialog" id="addSubCategory">
        <div class="vertical-alignment-helper px-3">
            <div class="modal-dialog vertical-align-center" role="document">
                <div class="modal-content">
                    <Form class="text-left" @submit="handleSubCategory" :validation-schema="schema" v-slot="{ errors }">
                        <div class="modal-header">
                            <h5 class="modal-title text-dark">{{ this.modalTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                id="modal-close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div v-if="!successful">
                                <Field name="category_id" id="category_id" type="hidden" v-model="category.id" />
                                <div class="form-group">
                                    <label class="text-dark" for="subcategory">Nama Sub Kategori</label>
                                    <Field name="subcategory" id="subcategory" type="text" class="form-control"
                                        v-model="category.text" :class="{ 'is-invalid': errors.subcategory }" />
                                    <ErrorMessage name="subcategory" class="invalid-feedback" as="div" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer bg-whitesmoke br">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                Close
                            </button>
                            <button :disabled="loading" type="submit" class="btn text-white bg-success">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                Simpan
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from "axios";

export default {
    name: "SubCategoriesChoiceComponent",
    props: ["from"],
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            subcategory: yup.string().required("Sub Kategori harus diisi!"),
        });

        return {
            successful: false,
            loading: false,
            message: "",
            modalTitle: "Buat Kategori Baru",
            schema,
            isLoading: false,
            category: { text: "", id: 0 },
            items: [],
        };
    },
    computed: {
        // loggedIn() {
        //   return this.$store.state.auth.status.loggedIn;
        // },
    },
    mounted() {
        if (localStorage.getItem("token") === null) {
            this.$router.push("/login");
        }
        this.fetchData();
        window.addEventListener('popstate', this.goBack);
    },
    methods: {
        handleSubCategory(data, form) {
            let _url = this.mainUrl + "/api/moopo/v2/owner/category";

            this.loading = true;

            const token = localStorage.getItem("token");

            let payload = {
                name: data.subcategory,
                type: this.$route.query.from,
                is_choice: true,
            };

            if (data.category_id != 0) {
                payload._method = "PUT";
                _url += "/" + data.category_id;
            }
            axios
                .post(_url, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    // console.log(res);
                    if (res.status) {
                        document.getElementById("modal-close").click();
                        this.loading = false;
                        this.fetchData();
                    }
                })
                .catch((err) => {
                    // console.log(err.response.data.message);
                    form.setFieldError("subcategory", err.response.data.message);
                    this.loading = false;
                    if (err.response.status == 401) {
                        this.$router.push("/login");
                    }
                });
        },
        fetchData() {
            this.isLoading = true;
            const token = localStorage.getItem("token");
            axios
                .get(this.mainUrl + "/api/moopo/v2/owner/category", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        from: this.$route.query.from,
                        is_choice: true
                    },
                })
                .then((res) => {
                    this.items = res.data;
                    this.isLoading = false;
                })
                .catch((err) => {
                    if (err.response.status == 401) {
                        this.$router.push("/login");
                    }
                });
        },
        prepareForm(id) {
            console.log(id);
            if (id != null) {
                this.category.text = this.items[id].name;
                this.category.id = this.items[id].id;
                this.modalTitle = "Edit Kategori";
            } else {
                this.category.text = "";
                this.category.id = 0;
                this.modalTitle = "Buat Kategori baru";
            }
        },
        deleteCategory(id) {
            const token = localStorage.getItem("token");
            axios
                .delete(this.mainUrl + "/api/moopo/v2/owner/category/" + id, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                    params: {
                        from: this.$route.query.from
                    },
                })
                .then((res) => {
                    // console.log(res);
                    if (res.status) {
                        this.fetchData();
                    }
                })
                .catch((err) => {
                    // console.log(err.response.data.message);
                    if (err.response.status == 401) {
                        this.$router.push("/login");
                    }
                });
        },
        goBack() {
            this.$router.push("/owner/product");
        },
    },
};
</script>

<style scoped>
/* Your scoped styles go here */
.action-bar {
    position: fixed;
    bottom: 10px;
    z-index: 90;
}

.btn-add {
    height: 55px;
    width: 55px;
}
</style>